import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';

import { Provider } from 'react-redux';
import { createStore as reduxCreateStore } from 'redux';
import rootReducer from '.';
const createStore = () => reduxCreateStore(rootReducer);

export const wrapRootElement = ({ element }) => (
  <Provider store={createStore()}>
    <ApolloProvider client={client}>{element}</ApolloProvider>
  </Provider>
);

// import React from 'react';

// import { GlobalContext } from './GlobalContext';

// export const wrapRootElement = ({ element }) => (
//   <ApolloProvider client={client}>
//     <GlobalContext>{element}</GlobalContext>
//   </ApolloProvider>
// );

// import React from 'react';

// import { ApolloProvider } from '@apollo/client';
// import { client } from './client';

// export default ({ element }) => (
//   <Provider store={createStore()}>
//     <ApolloProvider client={client}>{element}</ApolloProvider>
//   </Provider>
// );
