const initialState = {
  isDarkMode: false,
  mapHidden: false,
  searchTerm: '',
  criteria: {},
  categories: {},
  selectedMapentry: null,
  latLong: [7.436447, 46.944645],
  keepLatLong: false,
  zoom: 12,
};

const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE';
const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
const TOGGLE_CRITERION = 'TOGGLE_CRITERION';
const TOGGLE_MAP_HIDDEN = 'TOGGLE_MAP_HIDDEN';
const SET_SELECTED_MAPENTRY = 'SET_SELECTED_MAPENTRY';
const SET_LAT_LONG = 'SET_LAT_LONG';
const SET_KEEP_LAT_LONG = 'SET_KEEP_LAT_LONG';
const SET_ZOOM = 'SET_ZOOM';

export const toggleDarkMode = (isDarkMode) => ({
  type: TOGGLE_DARKMODE,
  isDarkMode,
});

export const setSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM,
  searchTerm,
});

export const toggleCriterion = (id) => ({
  type: TOGGLE_CRITERION,
  id,
});

export const toggleCategory = (id) => ({
  type: TOGGLE_CATEGORY,
  id,
});

export const toggleMapHidden = () => ({
  type: TOGGLE_MAP_HIDDEN,
});

export const setSelectedMapentry = (selectedMapentry) => ({
  type: SET_SELECTED_MAPENTRY,
  selectedMapentry,
});

export const setLatLong = (latLong) => ({
  type: SET_LAT_LONG,
  latLong,
});

export const setKeepLatLong = (keepLatLong) => ({
  type: SET_KEEP_LAT_LONG,
  keepLatLong,
});

export const setZoom = (zoom) => ({
  type: SET_ZOOM,
  zoom,
});

// const [zoom, setZoom] = useState(12);

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: action.isDarkMode };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.searchTerm };
    case TOGGLE_CATEGORY:
      let cats = { ...state.categories };
      if (cats[action.id] === true) {
        delete cats[action.id];
      } else {
        cats[action.id] = true;
      }
      return { ...state, categories: cats };

    case TOGGLE_CRITERION:
      let cri = { ...state.criteria };
      if (cri[action.id] === true) {
        delete cri[action.id];
      } else {
        cri[action.id] = true;
      }
      return { ...state, criteria: cri };
    case TOGGLE_MAP_HIDDEN:
      return { ...state, mapHidden: !state.mapHidden };
    case SET_SELECTED_MAPENTRY:
      return { ...state, selectedMapentry: action.selectedMapentry };

    case SET_LAT_LONG:
      return { ...state, latLong: action.latLong };
    case SET_KEEP_LAT_LONG:
      return { ...state, keepLatLong: action.keepLatLong };

    case SET_ZOOM:
      return { ...state, zoom: action.zoom };

    default:
      return state;
  }
};
